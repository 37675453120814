import {useState, useEffect, createContext} from 'react'
import {createUserWithEmailAndPassword,sendPasswordResetEmail,signInWithEmailAndPassword,signOut} from 'firebase/auth'
import {auth,db} from '../firebase'
import { collection, addDoc, setDoc,doc } from "firebase/firestore";


import { useNavigate } from 'react-router-dom'
import {toast, ToastContainer} from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import firebase from 'firebase/compat/app';


export const AuthContext = createContext({})
const qs = require('qs');

function AuthProvider({children}){
    const [user,setUser] = useState(null)
    const [loadingAuth,setLoadingAuth] = useState(false)
    const [loading,setLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(()=>{
      async function loaduser(){

        const torageUser = localStorage.getItem('@tricretPro')

        if(torageUser){
          setUser(JSON.parse(torageUser))
          setLoading(false)
        }

        setLoading(false)


      }
      loaduser()

    },[])

    //recupernado senha
    async function Recuperarsenha(email){
    await sendPasswordResetEmail(auth,email)
    .then(()=>{
      toast.success("Enlace enviado con éxito.")
     navigate("/")
    })

    }

    //logando usuario
    async function singIn(username,pw){

await signInWithEmailAndPassword(auth,username,pw)
.then((value)=>{

  let data = {
    uid:value.user.uid,
    email:value.user.email
   
  }

  setUser(data)
  storageUser(data)
  toast.success('Bienvenido...')
          
       
          navigate("/home")


})

       
        
       
   
          
          
        
    }

    async function singup(email,pw){


      setLoadingAuth(true)
      
     await createUserWithEmailAndPassword(auth, email, pw)
     .then((value)=>{
      
      const docRef =  setDoc(doc(db, "users", value.user.uid ), {
        latitude: 0,   
        longitude:0 
      });

      toast.success('Registrado con éxito')
      window.open('https://areamiembros.acessoappoficial.store/' , '_self');
     })
     .catch(()=>{
      console.log('err a cadastrado cm sucesso')

     })

   
      
  }

  async function Logout(){
    signOut(auth).then(()=>{
      toast.success('Utilice sempre IA 2024')
      storageUser(null)
      setLoading(false)
      setUser(null)
      navigate('/')

    })

   }

    async function storageUser(data){
        localStorage.setItem('@tricretPro', JSON.stringify(data))
    }

  


    
    return(
      
        <AuthContext.Provider
       
        value={{
            signed: !!user,
            user,
            singIn,
            singup,
            Recuperarsenha,
            Logout,
            loadingAuth,
            loading
           
        }}
        >
          
       {children}
       <ToastContainer/>
        </AuthContext.Provider>
    )
}

export default AuthProvider;