// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {getStorage} from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBBplPq_mNSjs5SWtc0C6FWMfvq81Uw77o",
  authDomain: "chamados-34ff3.firebaseapp.com",
  projectId: "chamados-34ff3",
  storageBucket: "chamados-34ff3.appspot.com",
  messagingSenderId: "43446821827",
  appId: "1:43446821827:web:9abe2c4cb3526a5cde9376",
  measurementId: "G-XVHW7T2PZ6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app) 
export const storage  = getStorage(app)