import React from 'react'
import './App.css'
export default function Termos() {
 return (
   <div className='contTermos'>
    <h4>TÉRMINOS DE USO — Viewer</h4>
 <p>
Viewer, Entidad Legal Privada describe, por la presente, las reglas de uso del sitio web espectadorspy.com y cualquier otro sitio web, tienda o aplicación operada por el propietario. Al navegar por este sitio web, consideramos que acepta los Términos de uso que se indican a continuación. Si no está de acuerdo con los términos de este acuerdo, le pedimos que deje de usar este sitio web, y mucho menos que se registre o envíe sus datos personales. Si cambiamos nuestros Términos de uso, publicaremos el nuevo texto en este sitio web, con la fecha de revisión actualizada. Podemos cambiar este documento en cualquier momento. Si hay un cambio sustancial en los términos de este acuerdo, podemos notificárselo utilizando la información de contacto que tenemos en nuestra base de datos o mediante una notificación. Su uso de este sitio web después de los cambios significa que acepta los Términos de uso revisados. Si, después de leer la versión revisada, no está de acuerdo con sus términos, cancele su acceso.
</p>

   </div>
  );
}